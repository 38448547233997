/* tslint:disable */
/* eslint-disable */
/**
 * Campus Framework API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@bitapp.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvitationResponseEnum,
    InvitationResponseEnumFromJSON,
    InvitationResponseEnumFromJSONTyped,
    InvitationResponseEnumToJSON,
} from './InvitationResponseEnum';

/**
 * 
 * @export
 * @interface ReplyRequest
 */
export interface ReplyRequest {
    /**
     * 
     * @type {string}
     * @memberof ReplyRequest
     */
    signature_hash: string;
    /**
     * 
     * @type {InvitationResponseEnum}
     * @memberof ReplyRequest
     */
    response: InvitationResponseEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ReplyRequest
     */
    accomodation_request: boolean;
}

export function ReplyRequestFromJSON(json: any): ReplyRequest {
    return ReplyRequestFromJSONTyped(json, false);
}

export function ReplyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signature_hash': json['signature_hash'],
        'response': InvitationResponseEnumFromJSON(json['response']),
        'accomodation_request': json['accomodation_request'],
    };
}

export function ReplyRequestToJSON(value?: ReplyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signature_hash': value.signature_hash,
        'response': InvitationResponseEnumToJSON(value.response),
        'accomodation_request': value.accomodation_request,
    };
}

