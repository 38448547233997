import { cloneElement, ComponentPropsWithoutRef, HTMLProps, ReactElement, SVGProps } from "react";
import { classes } from "../utility/functions";

interface FlexProps extends ComponentPropsWithoutRef<'div'> {
    vertical?: boolean;
}

const xmlns = 'http://www.w3.org/2000/svg';

const withProps = <P extends ClassNameProps & SVGProps<SVGElement>>(Component: ReactElement) => (p: P) => cloneElement(Component, { ...{ ...p, xmlns } });

const Spinner = withProps(<svg viewBox="0 0 512 512"><path fill="currentColor" d="M460.115 373.846l-6.941-4.008c-5.546-3.202-7.564-10.177-4.661-15.886 32.971-64.838 31.167-142.731-5.415-205.954-36.504-63.356-103.118-103.876-175.8-107.701C260.952 39.963 256 34.676 256 28.321v-8.012c0-6.904 5.808-12.337 12.703-11.982 83.552 4.306 160.157 50.861 202.106 123.67 42.069 72.703 44.083 162.322 6.034 236.838-3.14 6.149-10.75 8.462-16.728 5.011z"></path></svg>);
const buttonStyle = 'flex justify-center text-sm font-medium rounded-md';
const variants = {
    primary: 'text-white bg-primary py-2 px-4 disabled:bg-gray-300 disabled:text-gray-500',
    secondary: 'text-white bg-secondary py-2 px-4 disabled:bg-gray-300 disabled:text-gray-500',
} as const;
const textVariants = {
    primary: 'text-white',
    secondary: 'text-black',
    white: 'text-black',
    danger: 'text-white',
    skeleton: '',
} as const;
type Variant = keyof typeof variants;
interface ButtonProps extends ClassNameProps, ChildrenProps, HTMLProps<HTMLButtonElement> {
    variant?: Variant;
    type?: 'button' | 'submit';
    isLoading?: boolean;
}

export const Flex = ({ className, vertical = false, ...p }: ClassNameProps & ChildrenProps & FlexProps) => <div className={classes('flex', vertical && 'flex-col', className)} {...p} />;
export const Well = ({ className, ...p }: ClassNameProps & ChildrenProps & FlexProps) => <Flex className={classes('w-full rounded-md bg-white shadow', className)} {...p} />;
const WellTitle = (p: ChildrenProps & ClassNameProps) => <span className='mb-4 font-medium' {...p} />;
Well.Title = WellTitle;
export const Container = ({ className, ...p }: ClassNameProps & ChildrenProps) => <div className={classes('flex-grow p-4', className)} {...p} />;
export const Button = ({ className, variant = 'primary', isLoading = false, children, ...props }: ButtonProps) => (
<button className={classes(buttonStyle, variants[variant], className)} {...props}>
    {isLoading ? <Spinner className={classes(textVariants[variant], 'h-5 w-5 animate-spin')} /> : children}
</button>);