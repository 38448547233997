/* tslint:disable */
/* eslint-disable */
/**
 * Campus Framework API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@bitapp.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplyInfo
 */
export interface ReplyInfo {
    /**
     * 
     * @type {number}
     * @memberof ReplyInfo
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplyInfo
     */
    participant: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyInfo
     */
    invitation_group_name: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyInfo
     */
    starts_at: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyInfo
     */
    ends_at: string;
    /**
     * 
     * @type {string}
     * @memberof ReplyInfo
     */
    already_replied?: string;
}

export function ReplyInfoFromJSON(json: any): ReplyInfo {
    return ReplyInfoFromJSONTyped(json, false);
}

export function ReplyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'participant': json['participant'],
        'invitation_group_name': json['invitation_group_name'],
        'starts_at': json['starts_at'],
        'ends_at': json['ends_at'],
        'already_replied': !exists(json, 'already_replied') ? undefined : json['already_replied'],
    };
}

export function ReplyInfoToJSON(value?: ReplyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'participant': value.participant,
        'invitation_group_name': value.invitation_group_name,
        'starts_at': value.starts_at,
        'ends_at': value.ends_at,
        'already_replied': value.already_replied,
    };
}

