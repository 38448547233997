/* tslint:disable */
/* eslint-disable */
/**
 * Campus Framework API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@bitapp.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationResponseEnum {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected'
}

export function InvitationResponseEnumFromJSON(json: any): InvitationResponseEnum {
    return InvitationResponseEnumFromJSONTyped(json, false);
}

export function InvitationResponseEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationResponseEnum {
    return json as InvitationResponseEnum;
}

export function InvitationResponseEnumToJSON(value?: InvitationResponseEnum | null): any {
    return value as any;
}

