import { InvitationResponseEnum, ReplyRequest } from "api";
import Form from "components/Form";
import { Button, Container, Flex, Well } from "components/UI";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import { useApi } from "services/api";
import { formatDate, getErrorFromResponse } from "utility/functions";
import { useValidationErrors } from "utility/hooks";
const QUERY_KEY = 'getReplyInfo';
type FormType = { accomodation_request?: string | undefined };

export default function Reply() {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id } = useParams();

    let [searchParams] = useSearchParams();
    if (!searchParams.has('signature_hash')) {
        navigate('error');
    }

    let signature = searchParams.get('signature_hash');
    if (signature === '' || signature === null) {
        navigate('error');
    }

    const { invitations } = useApi();
    const { data: response, isLoading } = useQuery([QUERY_KEY], () => invitations[QUERY_KEY]({
        invitationId: Number(id),
        signature_hash: signature ?? '',
    }), {
        enabled: Boolean(id) && Boolean(signature)
    });

    const mutationReply = useMutation((replyRequest: ReplyRequest) => invitations.invitationReply({
        invitationId: Number(id),
        ReplyRequest: replyRequest,
    }));
    const defaultValues: FormType = {
        accomodation_request: '',
    };
    const [formState, setFormState] = useState(defaultValues);

    const [errors, onError, clearErrors] = useValidationErrors();
  
    function onSubmitReply(invitationResponse: InvitationResponseEnum) {
        const request: ReplyRequest = {
            signature_hash: signature ?? '',
            response: invitationResponse,
            accomodation_request: formState.accomodation_request === '1',
        };
        mutationReply.mutate(request, {
            onSuccess: () => {
                queryClient.invalidateQueries(QUERY_KEY);
            }
        });
    }

    function singledate() {
        if (!response) return;
        return formatDate(response.data.starts_at) !== formatDate(response.data.ends_at) ? false : true;
    }

    const isSingleDate = singledate();

    return (
        <>
            <Container>
                <Well vertical className="p-4">
                    <Well.Title>Informazioni invito</Well.Title>
                    <span><strong>Partecipante: </strong>{response?.data.participant}</span>
                    <span><strong>Eventi: </strong>{response?.data.invitation_group_name} dal {formatDate(response?.data.starts_at as string)} al {formatDate(response?.data.ends_at as string)}</span>
                </Well>
                {response && !response.data.already_replied && (
                    <Flex className='justify-center p-10'>
                        <div>
                            <Form onChange={setFormState} defaultValues={defaultValues} errors={errors}>
                                {!isSingleDate && (
                                    <Form.Select label='Pernottamento' name='accomodation_request' className='w-72'>
                                        <option value="1">Voglio pernottare</option>
                                        <option value="0">Non voglio pernottare</option>
                                    </Form.Select>
                                )}
                                <Flex className='space-children mt-10'>
                                    <Button isLoading={mutationReply.isLoading} onClick={() => onSubmitReply(InvitationResponseEnum.Rejected)} variant='secondary'>NON ACCETTO</Button>
                                    <Button isLoading={mutationReply.isLoading} disabled={!isSingleDate && formState.accomodation_request === ''} onClick={() => onSubmitReply(InvitationResponseEnum.Accepted)}>ACCETTO</Button>
                                </Flex>
                            </Form>
                        </div>
                    </Flex>
                )}
                {response && response.data.already_replied && (
                    <Flex className="justify-center mt-4 text-3xl leading-relaxed tracking-widest py-16">
                        <span>{ response.data.already_replied }</span>
                    </Flex>
                )}
          
            </Container>
        </>
    );
}