import { useState } from 'react';
import ApiProvider from './api';

export default function ServicesProvider({ children }: ChildrenProps) {
  const [accessToken, setAccessToken] = useState<string>('');

  return (
    <ApiProvider accessToken={accessToken}>
      {children}
    </ApiProvider>
  );
}
