import Header from 'components/Header';
import Logo from 'components/Logo';
import { Flex } from 'components/UI';
import routes from 'pages/routes';
import { Suspense, useMemo, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useNavigate, useRoutes } from 'react-router';
import { Slide, toast, ToastContainer } from 'react-toastify';
import ServicesProvider from 'services';
import { getErrorFromResponse } from 'utility/functions';

export default function App() {
  const navigate = useNavigate();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
          },
        },
        queryCache: new QueryCache({
          onError(error) {
            if (error instanceof Response && error.status === 401) navigate('/');
          },
        }),
        mutationCache: new MutationCache({
          async onError(error) {
            if (error instanceof Response && error.status !== 422) 
            {
              const errorMessage = await getErrorFromResponse(error);
              toast.error(errorMessage);
            }

          },
        }),
      }),
    [navigate],
  );

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ServicesProvider>
          <Suspense fallback={<Loading />}>
            <ErrorBoundary fallback={<div>Errore</div>}>

              <Router />
            </ErrorBoundary>

          </Suspense>
        </ServicesProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer transition={Slide} hideProgressBar={true} position='bottom-left' newestOnTop autoClose={5000} theme='colored' />
    </>
  );
}

const Loading = () => (
  <div className='space-children flex h-screen w-screen items-center justify-center'>
    <Logo className='h-28' />
  </div>
);

function Router() {
  const tree = useRoutes(routes);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const intersectedRef = useRef<HTMLDivElement>(null);
  return (
    <Flex vertical className='relative h-screen w-screen overflow-hidden'>
      <Header />
      <main className='relative flex flex-grow flex-col overflow-y-auto' ref={scrollableRef}>
        <div ref={intersectedRef} />
        {tree}
      </main>
    </Flex>
  );
}
