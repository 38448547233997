//import * as openApi from '/Users/lindaminotti/Sites/campus-framework-invitation-reply-frontend/src/api/index';
import { createContext, useContext, useMemo } from 'react';
import * as openApi from 'api';

const { API_BASE_PATH } = window.__env;

const getHttpClient = (config?: openApi.Configuration) => ({
    invitations: new openApi.InvitationsApi(config),
});

type Context = ReturnType<typeof getHttpClient>;
const ApiContext = createContext<Context>(getHttpClient());

export const useApi = () => useContext(ApiContext);

const getConfig = (accessToken: string) =>
    new openApi.Configuration({
        basePath: API_BASE_PATH ?? '',
        accessToken: `Bearer ${accessToken}`,
        headers: { Accept: 'application/json' },
    });

export default function ApiProvider({ children, accessToken }: ChildrenProps & { accessToken: string }) {
    const value = useMemo(() => getHttpClient(getConfig(accessToken)), [accessToken]);
    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}