import dayjs from "dayjs";

type ValidDate = string | Date | dayjs.Dayjs;
export const classes = (...classes: (false | null | undefined | string)[]) => classes.filter(Boolean).join(' ');
export const isEmpty = (v: any) => v === '' || v === undefined || v === null || (Array.isArray(v) && v.length === 0) || (typeof v === 'object' && Object.keys(v).length === 0);
export function emptyFunction() {
    // do nothing
  }
export const getErrorFromResponse = async (error: unknown) => 
{
  if (error instanceof Response) {
    const jsonError = await error.json();
    return jsonError.message;
  }
}
export const formatDate = (d: ValidDate) => dayjs(d).format('DD-MM-YYYY');