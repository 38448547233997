/* tslint:disable */
/* eslint-disable */
/**
 * Campus Framework API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@bitapp.it
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReplyInfoResponse,
    ReplyInfoResponseFromJSON,
    ReplyInfoResponseToJSON,
    ReplyRequest,
    ReplyRequestFromJSON,
    ReplyRequestToJSON,
} from '../models';

export interface GetReplyInfoRequest {
    invitationId: number;
    signature_hash?: string;
}

export interface InvitationReplyRequest {
    invitationId: number;
    ReplyRequest: ReplyRequest;
}

/**
 * 
 */
export class InvitationsApi extends runtime.BaseAPI {

    /**
     * Get reply info
     */
    async getReplyInfoRaw(requestParameters: GetReplyInfoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ReplyInfoResponse>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling getReplyInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.signature_hash !== undefined) {
            queryParameters['signature_hash'] = requestParameters.signature_hash;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/invitations/{invitationId}/info`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReplyInfoResponseFromJSON(jsonValue));
    }

    /**
     * Get reply info
     */
    async getReplyInfo(requestParameters: GetReplyInfoRequest, initOverrides?: RequestInit): Promise<ReplyInfoResponse> {
        const response = await this.getReplyInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set reply info
     */
    async invitationReplyRaw(requestParameters: InvitationReplyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.invitationId === null || requestParameters.invitationId === undefined) {
            throw new runtime.RequiredError('invitationId','Required parameter requestParameters.invitationId was null or undefined when calling invitationReply.');
        }

        if (requestParameters.ReplyRequest === null || requestParameters.ReplyRequest === undefined) {
            throw new runtime.RequiredError('ReplyRequest','Required parameter requestParameters.ReplyRequest was null or undefined when calling invitationReply.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/invitations/{invitationId}/reply`.replace(`{${"invitationId"}}`, encodeURIComponent(String(requestParameters.invitationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ReplyRequestToJSON(requestParameters.ReplyRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set reply info
     */
    async invitationReply(requestParameters: InvitationReplyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.invitationReplyRaw(requestParameters, initOverrides);
    }

}
