import { HTMLProps, useEffect } from "react";
import { DeepPartial, FormProvider, Mode, Path, RegisterOptions, SubmitHandler, UnpackNestedValue, useForm, useFormContext, UseFormGetValues, useWatch, ValidateResult } from "react-hook-form";
import { classes, emptyFunction, isEmpty } from "../../utility/functions";
import { Flex } from "../UI";

type DefaultValues<T> = UnpackNestedValue<DeepPartial<T>>;
interface FormProps<T> extends ChildrenProps, ClassNameProps, Omit<HTMLProps<HTMLFormElement>, 'onSubmit' | 'onChange'> {
    defaultValues?: DefaultValues<T>;
    onSubmit?: SubmitHandler<T>;
    onChange?: (p: DefaultValues<T>) => void;
    errors?: ValidationErrors;
    mode?: Mode;
}

export default function Form<T extends Record<string, any>>({ defaultValues, children, onSubmit = emptyFunction, onChange, mode = 'onSubmit', errors, ...props }: FormProps<T>) {
    const methods = useForm({ defaultValues, mode, shouldUnregister: true });
    const inputs = useWatch({ control: methods.control });

    useEffect(() => {
        onChange?.(inputs);
    }, [onChange, inputs]);

    useEffect(() => {
        if (!errors || isEmpty(errors)) methods.clearErrors();
        else Object.entries(errors).forEach(([name, err]) => methods.setError(name as Path<T>, { message: err[0] }));
    }, [methods, errors]);

    return (
        <form onSubmit={methods.handleSubmit(onSubmit)} {...props}>
            <FormProvider {...methods}>{children}</FormProvider>
        </form>
    );
}

interface InputProps {
    name: string;
    label?: string;
}
type Options<T> = { options?: CustomOptions<T> };
type CustomValidate<TFieldValue> = (value: TFieldValue, getValues: UseFormGetValues<any>) => ValidateResult | Promise<ValidateResult>;
type CustomOptions<TFieldValue> = Omit<RegisterOptions, 'validate'> & { validate?: CustomValidate<TFieldValue> | Record<string, CustomValidate<TFieldValue>> };
const labelStyle = 'text-sm inline-block';
const inputStyle = 'w-full text-sm py-1 px-3 border border-gray-300 focus:border-gray-300 placeholder-gray-400 outline resize-none rounded-md shadow-sm';
function Select<T>({ children, name, label, className, options = {}, ...props }: HTMLProps<HTMLSelectElement> & ChildrenProps & ClassNameProps & InputProps & Options<T>) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    // const opts = useValidations(options);
    return (
        <Flex vertical className='mb-4'>
            <label className={classes(labelStyle, className)}>
                {label}
                <select className={classes('pr-10', inputStyle)} {...register(name)} {...props}>
                    <option value='' disabled>- Qualsiasi -</option>
                    {children}
                </select>
            </label>
            {/* <ErrorMessage message={getErrorMessage(errors, name)} /> */}
        </Flex>
    );
}

Form.Select = Select;