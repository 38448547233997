import { Navigate } from 'react-router';
import Error from './Error';
import Reply from './Reply';

const routes = [
    { path: '', element: <Navigate to="error" /> },
    { path: 'error', element: <Error /> },
    { path: 'reply/:id', element: <Reply /> },
    { path: '*', element: <Navigate to="error" /> },
];

export default routes;
