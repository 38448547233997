import { useCallback, useState } from 'react';
import { getErrorFromResponse } from './functions';

export function useValidationErrors(): [ValidationErrors, (error: unknown) => void, () => void] {
  const [errors, setErrors] = useState({});

  const onError = useCallback(async error => {
    if (error instanceof Response && error.status === 422) {
      setErrors((await getErrorFromResponse(error)).errors);
    }
  }, []);

  const clearErrors = useCallback(() => setErrors({}), []);

  return [errors, onError, clearErrors];
}
